import Featurettes from "../../components/Featurettes";
import FullHeightHero from "../../components/FullHeightHero";
import Layout from "../../layout";

export default function Storytelling() {
  return (
    <Layout>
      <FullHeightHero
        img="/img/storytelling/cover-storytelling.jpg"
        title="Storytelling"
        lead="Simuladores criados a partir das necessidades de cada cliente."
        textInverted
      />
      <Featurettes
        items={[
          {
            title: "Intimidade com Avaliação de Dados.",
            lead: "Consolide oportunidades a partir da avaliação de dados e cenários complexos.",
            img: "/img/storytelling/bg-dados-400.jpg",
          },
          {
            title: "Storytelling e a necessidade de cada cliente.",
            lead: "Soluções baseadas nas necessidades de cada cliente e no processo de tomada de decisão corporativo.",
            img: "/img/storytelling/bg-story-400.jpg",
          },
          {
            title: "POWER BI - Novas interfaces, novas oportunidades.",
            lead: "O POWER BI da Microsoft como facilitador para interpretação de dados.",
            img: "/img/storytelling/bg-powerbi-400.jpg",
          },
        ]}
      />
    </Layout>
  );
}
