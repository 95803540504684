import Featurettes from "../../components/Featurettes";
import FullHeightHero from "../../components/FullHeightHero";
import Layout from "../../layout";

export default function Maximize() {
  return (
    <Layout>
      <FullHeightHero
        img="/img/maximize/cover-maximize.jpg"
        title="Maximizar"
        lead={
          <p className="lead">
            Aprender, praticar, repensar e aplicar novamente. <br /> O segredo
            para alcançar sustentabilidade.
          </p>
        }
      />
      <Featurettes
        items={[
          {
            title: "OKRs, BSC, Indicadores de Performance.",
            subtitle: "O mundo corporativo.",
            lead: "As metas associadas ao desenvolvimento de cultura organizacional e os impactos no comportamento das organizações.",
            img: "/img/maximize/bg-indicadores-400.jpg",
          },
          {
            title: "Visão sistêmica integrada ao processo gerencial.",
            subtitle: "Interfaces corporativas.",
            lead: "A construção dos modelos e conceitos que buscam consolidar visão  sistêmica. Redução das interfaces corporativas, comunicação eficiente e resultados integrados.",
            img: "/img/maximize/bg-integrado-400.jpg",
          },
          {
            title: "ESG - Boas práticas ambientais, sociais e de governança.",
            subtitle: "O PRESENTE.",
            lead: "As questões ambientais, sociais e de governança consideradas essenciais nas análises de riscos e nas decisões de investimentos.",
            img: "/img/maximize/bg-esg-400.jpg",
          },
        ]}
      />
    </Layout>
  );
}
