export default function Credits() {
  return (
    <div className="container">
      <h1>Image Credits</h1>
      <p className="lead">
        Giving credit where credit is due is always the respectful way to say
        thanks, and is sometimes a requirement to use an image. Depending on the
        license terms set by the creator, they may give you special permission
        to use their image as long as you follow the terms. This is most common
        in a Creative Commons (CC) type license, where the creator grants
        permission at no cost, but requires something in return, e.g.,
        attribution, no modifications, or non-commercial use.
      </p>
      <p>
        In this section, we give credit to the creators of the images used in
        the COMPSIM website.
      </p>
      <ul>
        <li>
          <a
            href="https://www.freepik.com/photos/student-group"
            rel="noreferrer"
            target="_blank"
          >
            Student group photo created by javi_indy - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.freepik.com/vectors/gamepad"
            rel="noreferrer"
            target="_blank"
          >
            Gamepad vector created by stockgiu - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.freepik.com/photos/globe-hand"
            rel="noreferrer"
            target="_blank"
          >
            Globe hand photo created by freepik - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.freepik.com/vectors/portrait-illustration"
            rel="noreferrer"
            target="_blank"
          >
            Portrait illustration vector created by freepik - www.freepik.com
          </a>
        </li>
        <li>
          <a
            href="https://www.freepik.com/photos/coding"
            rel="noreferrer"
            target="_blank"
          >
            Coding photo created by DCStudio - www.freepik.com
          </a>
        </li>
        <li>
          <a href="https://www.freepik.com/vectors/waiting-area">
            Waiting area vector created by katemangostar - www.freepik.com
          </a>
        </li>
        <li>
          <a href="https://www.freepik.com/vectors/office-floor">
            Office floor vector created by macrovector - www.freepik.com
          </a>
        </li>
        <li>
          <a href="https://www.freepik.com/vectors/3d-floor">
            3d floor vector created by macrovector - www.freepik.com
          </a>
        </li>
      </ul>
    </div>
  );
}
