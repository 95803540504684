import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <header className="p-3 mb-3 border-bottom">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <NavLink
            to="/"
            className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
          >
            <img src="/logo-compsim-lowres.png" alt="COMPSIM" />
          </NavLink>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li>
              <NavLink to="/storytelling" className="nav-link px-2 link-dark">
                Storytelling
              </NavLink>
            </li>
            <li>
              <NavLink to="/interactive" className="nav-link px-2 link-dark">
                Interativo & Amigável
              </NavLink>
            </li>
            <li>
              <NavLink to="/maximize" className="nav-link px-2 link-dark">
                Maximizar
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
