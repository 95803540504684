import { NavLink } from "react-router-dom";
import { goUp } from "../../utils";

export default function Marketing() {
  return (
    <div className="container marketing">
      <div className="row text-center">
        <div className="col-lg-4">
          <img
            src="/img/home/mkt-storytelling.jpg"
            className="rounded-circle"
            alt=""
            width="140"
            height="140"
          />
          <h2>Storytelling</h2>
          <p>Simuladores criados a partir das necessidades de cada cliente.</p>
          <p>
            <NavLink
              className="btn btn-secondary"
              to="/storytelling"
              onClick={goUp}
            >
              Ver detalhes »
            </NavLink>
          </p>
        </div>
        <div className="col-lg-4">
          <img
            src="/img/home/mkt-interactive.jpg"
            className="rounded-circle"
            alt=""
            width="140"
            height="140"
          />
          <h2>Interativo &amp; Amigável</h2>
          <p>
            Cenários interativos com a apresentação de personagens e avatares.
          </p>
          <p>
            <NavLink
              className="btn btn-secondary"
              to="/interactive"
              onClick={goUp}
            >
              Ver detalhes »
            </NavLink>
          </p>
        </div>
        <div className="col-lg-4">
          <img
            src="/img/home/mkt-maximize.jpg"
            className="rounded-circle"
            alt=""
            width="140"
            height="140"
          />
          <h2>Maximizar</h2>
          <p>
            Aprender, praticar, repensar e aplicar novamente. O segredo para
            alcançar sustentabilidade.{" "}
          </p>
          <p>
            <NavLink
              className="btn btn-secondary"
              to="/maximize"
              onClick={goUp}
            >
              Ver detalhes »
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}
