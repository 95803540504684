import { Route, Routes } from "react-router-dom";
import Layout from "../../layout";
import Credits from "./credits";
import Privacy from "./privacy";
import Terms from "./terms";

export default function Docs() {
  return (
    <Layout>
      <Routes>
        <Route path="credits" element={<Credits />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
      </Routes>
    </Layout>
  );
}
