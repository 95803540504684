import Carousel from "../../components/Carousel";

export default function Hero() {
  return (
    <Carousel
      style={{ marginTop: "-20px" }}
      items={[
        {
          img: "/img/home/bg-hero-1.jpg",
          title: "Simulação de negócios baseadas em Storytelling.",
          subtitle: "Aprendizado centrado nos objetivos de cada negócio",
          text: "COMPSIM é uma plataforma de interações com Stakeholders desenvolvida a partir de métricas consistentes vivencidas no ambiente corporativo.",
        },
        {
          img: "/img/home/bg-hero-2.jpg",
          title: "Vivencie experiências corporativas em um ambiente simulado.",
          text: "Reduza a interface entre os departamentos consolidando visão sistêmica e tomada de decisão. ",
        },
        {
          img: "/img/home/bg-hero-3.jpg",
          title: "Indicadores de desempenho baseados em OKRs e BSC.",
          text: "Os mais modernos conceitos de indicadores de desempenho aplicados no mundo corporativo.",
        },
      ]}
    />
  );
}
