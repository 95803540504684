import Featurettes from "../../components/Featurettes";

export default function Features() {
  return (
    <Featurettes
      items={[
        {
          title: "Storytelling desenvolvido a partir de Stakeholders.",
          subtitle: "Cenários desafiadores.",
          lead: (
            <>
              Mantenha visão baseada nos princícios ESG de sustentabilidade.{" "}
              <br />
              <br />
              Pensado para desenvolver profissionais tomadores de decisão e que
              necessitam de equilibrío nas competências comportamentais e de
              liderança.
            </>
          ),
          img: "/img/home/bg-featurette-01.jpg",
        },
        {
          title: "Faça diferença e consolide cenários.",
          subtitle: "Conheça nossas soluções.",
          lead: (
            <>
              A COMPSIM é uma empresa especializada no desenvolvimento de
              simulações empresariais com mais de 15 anos de experiência.
            </>
          ),
          img: "/img/home/bg-enterprise.jpg",
        },
        {
          title: "Metaverso - A nova geração de simuladores.",
          subtitle: "O FUTURO.",
          lead: (
            <>
              A nova geração de simuladores de negócios será apoiada em
              metaverso com interações entre Stakeholders e tomadores de
              decisão.
            </>
          ),
          img: "/img/home/bg-metaverse.jpg",
        },
      ]}
    />
  );
}
