import { Route, Routes } from "react-router-dom";
import Docs from "./docs";
import Home from "./home";
import Interactive from "./interactive";
import Maximize from "./maximize";
import Storytelling from "./storytelling";

export default function Pages() {
  return (
    <Routes>
      <Route path="storytelling" element={<Storytelling />} />
      <Route path="maximize" element={<Maximize />} />
      <Route path="interactive" element={<Interactive />} />
      <Route path="docs/*" element={<Docs />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}
