import Featurettes from "../../components/Featurettes";
import FullHeightHero from "../../components/FullHeightHero";
import Layout from "../../layout";

export default function Interactive() {
  return (
    <Layout>
      <FullHeightHero
        img="/img/interactive/cover-interactive.jpg"
        title="Interativo & Amigável"
        lead="Cenários interativos com a apresentação de personagens e avatares."
        textInverted
      />
      <Featurettes
        items={[
          {
            title: "Escolha seu personagem.",
            lead: "Amplie seus conhecimentos por meio de interações em ambientes desafiadores.",
            img: "/img/interactive/bg-avatar-400.jpg",
          },
          {
            title: "Simule tendências e avalie impactos.",
            lead: "Ambiente livre de risco onde os erros são avaliados como oportunidades de melhorias",
            img: "/img/interactive/bg-tendencia-400.jpg",
          },
          {
            title: "Desafiador - O processo de tomada de decisão",
            lead: "Aderência a cultura organizacional nos desafios gerados pelos trade-offs corporativos.",
            img: "/img/interactive/bg-desafios-400.jpg",
          },
        ]}
      />
    </Layout>
  );
}
