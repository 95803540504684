import Layout from "../../layout";
import Features from "./Features";
import Hero from "./Hero";
import Marketing from "./Marketing";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <div className="m-5">&nbsp;</div>
      <Marketing />
      <Features />
    </Layout>
  );
}
