import { ReactNode } from "react";

export interface IFeaturette {
  title: string;
  subtitle?: string;
  lead?: ReactNode;
  img: string;
}

function renderFeaturette(item: IFeaturette, index: number) {
  const orders =
    index % 2 === 1
      ? ["order-md-2", "order-md-1"]
      : ["order-md-1", "order-md-2"];
  return (
    <div key={item.title}>
      <hr className="featurette-divider" />
      <div className="row featurette">
        <div className={`col-md-7 ${orders[0]}`}>
          <h2 className="featurette-heading">
            {item.title}
            &nbsp;<span className="text-muted">{item.subtitle}</span>
          </h2>
          <p className="lead">{item.lead}</p>
        </div>
        <div className={`col-md-5 ${orders[1]}`}>
          <img
            src={item.img}
            className="featurette-img rounded"
            alt=""
            style={{ width: "100%", minHeight: "400px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default function Featurettes(props: { items: IFeaturette[] }) {
  const { items } = props;
  return <div className="container">{items.map(renderFeaturette)}</div>;
}
