import { ReactNode } from "react";

export default function FullHeightHero(props: {
  img: string;
  title: string;
  lead: ReactNode;
  textInverted?: boolean;
}) {
  const { img, title, lead, textInverted } = props;
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        height: "calc(100vh - 55px)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: textInverted ? "#000" : "#fff",
        textShadow: "rgb(128, 128, 128) 1px 0px",
        marginTop: "-20px",
        marginBottom: "40px",
      }}
    >
      <h1>{title}</h1>
      {lead}
    </div>
  );
}
