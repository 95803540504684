import { CSSProperties, ReactNode, useState } from "react";
import { uuid } from "../utils";

export interface ICarousel {
  title: string;
  img: string;
  subtitle?: string;
  text?: string;
  action?: ReactNode;
}

const carouselIndicatorItem = (
  target: string,
  index: number,
  active: boolean
) => (
  <button
    type="button"
    data-bs-target={target}
    data-bs-slide-to={index.toString()}
    className={active ? "active" : undefined}
    aria-current={active ? "true" : "false"}
    aria-label={"Slide " + (index + 1)}
  />
);
function carouselIndicators(
  target: string,
  items: ICarousel[],
  active: number
) {
  return (
    <div className="carousel-indicators">
      {items.map((_item, index) =>
        carouselIndicatorItem(target, index, index === active)
      )}
    </div>
  );
}

const carouselItem = (
  item: ICarousel,
  active: boolean,
  height: string = "600px"
) => (
  <div className={active ? "carousel-item active" : "carousel-item"}>
    <div
      style={{
        width: "100%",
        height: height,
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${item.img})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    />

    <div
      className="carousel-caption d-none d-md-block text-shadow"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <h2>{item.title}</h2>
      {item.subtitle ? <p className="lead">{item.subtitle}</p> : null}
      <p>{item.text}</p>
      {item.action}
    </div>
  </div>
);

function carouselControl(target: string) {
  return (
    <>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={target}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={target}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </>
  );
}

export default function Carousel(props: {
  items: ICarousel[];
  style?: CSSProperties;
}) {
  const { items, style } = props;
  const [uid] = useState("u" + uuid());
  const target = "#" + uid;
  return (
    <div id={uid} className="carousel slide" style={style}>
      {carouselIndicators(target, items, 0)}
      <div className="carousel-inner">
        {items.map((item, index) => carouselItem(item, index === 0, "600px"))}
      </div>
      {carouselControl(target)}
    </div>
  );
}
